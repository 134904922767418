// components/Header.jsx
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {FaBars, FaTimes, FaLinkedin} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';
import './header.css';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
    }

    handleMenuToggle = () => {
        this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));
    };

    render() {
        const { isMenuOpen } = this.state;

        return (
            <header className="header">
                <div className="nav-container">
                    <FaBars className="hamburger-icon" onClick={this.handleMenuToggle} />

                </div>
                <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        initial={{ y: '-100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '-100%' }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                        className="full-page-menu"
                    >
                        <FaTimes className="close-icon" onClick={this.handleMenuToggle} />
                        <ul className="nav-list">
                            <li className="nav-item">
                                <HashLink smooth to="#experienceProf" className="link" onClick={this.handleMenuToggle}>
                                    Professional Experience
                                </HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink smooth to="#experienceEdu" className="link" onClick={this.handleMenuToggle}>
                                    Education
                                </HashLink>
                            </li>
                                <Link to="https://www.linkedin.com/in/danilo-dragoslav-vuita/" className="link" onClick={this.handleMenuToggle}>
                                    <FaLinkedin className="socials"/>
                                </Link>
                        </ul>
                    </motion.div>
                )}
                </AnimatePresence>
            </header>
        );
    }
}

export default Header;
