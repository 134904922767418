import React, {Component} from 'react';
import './Home.css'
import {ProfilePicture, Timeline} from "../../Components/index";
import profilePic from '../../Assets/1617721273142.jpeg'

const events = [
    {
        startMonth: 'January',
        startYear: 2020,
        endMonth: 'January',
        endYear: 2021,
        title: 'SAP Intern',
        description: 'Internship at Continental Automotive Romania.' +
            ' The internship was based on developing knowledge in the SAP ERP system, with a focus on the FI-CO modules ' +
            'During this internship I took part in various company projects together with my colleagues, thus developing my\n' +
            'teamwork and communication skills.',
        tags: [],
    },
    {
        startMonth: 'August',
        startYear: 2021,
        endMonth: 'July',
        endYear: 2022,
        title: 'Software Developer',
        description: 'Part of the Special Web Applications at Continental Automotive Romania  team developing and supporting projects on-demand. - Working or assisting other\n' +
            'developers with fixing issues throughout the application code, publishing and installing other applications. - Working or\n' +
            'assisting other developers with database related inquiries. - Managing or assisting other developers with project\n' +
            'management issues or topics.\n',
        tags: [
            {name:"C#",color:"blue"},
            {name:".NET",color:"purple"},
            {name:"SQL",color:"yellow"},
            {name:"Tortoise SVN", color:"orange"},
            {name:"Jira",color:"blue"}
        ],
    },
    {
        startMonth: 'July',
        startYear: 2022,
        title: 'Software Development Engineer ',
        description: 'As a Software Developer Engineer at GoDaddy Services, part of the Engineering Department in the GoDaddy domains team. The role focuses\n' +
            'on : Writing highly scalable, highly available, cloud-ready applications Using data and analytics to improve the product\n' +
            'and customer experience Solving end-to-end problems across the service stack Accountable for code quality, including\n' +
            'writing unit and integration tests Contributing to the full development stack from the Ul/API through the persistence\n' +
            'layer\n',
        tags: [
            {name:"C#",color:"blue"},
            {name:".NET",color:"purple"},
            {name:"SQL",color:"yellow"},
            {name:"Jira",color:"blue"},
            {name:"GitHub",color:"purple"},
            {name:"Docker",color:"blue"},
            {name:"Unit & Integration Testing",color:"green"},
        ],
    },
];
const education =[
    {
        startMonth: 'September',
        startYear: 2021,
        endMonth: 'July',
        endYear: 2024,
        title: 'Master’s degree in Big Data - West University, Timisoara',
        description: 'The main objective of the Big Data master program is to provide knowledge and skills, so at the end of the learning\n' +
            'cycle, the following skills will be mastered: designing efficient and robust models, statistical data analysis,\n' +
            'implementation and use of data mining algorithms, use of big data technologies, implementation of scalable\n' +
            'applications',
        tags: [],
    },
    {
        startMonth: 'February',
        startYear: 2021,
        endMonth: 'June',
        endYear: 2021,
        title: 'Erasmus+ - Johannes Kepler Universitat, Linz ',
        description: 'A semester abroad with the Erasmus+ programme',
        tags: [],
    },{
    startMonth: 'September',
    startYear: 2018,
    endMonth: 'July',
    endYear: 2021,
    title: 'Bachelor’s degree - West University, Timisoara',
    description: 'Bachelor’s degree in Computer Science, Computer Science in English programme.',
    tags: [],
}
];

class Home extends Component {
    render() {
        return (
            <section>
                <div className="hero">
                    <div className="col-1-hero">
                        <ProfilePicture src={profilePic} alt={""}/>
                        <h1>/ Software Development Engineer / <br/> / Web Development Enthusiast /</h1>
                    </div>
                    <div className="col-2-hero">
                        <p className="text-about-hero">
                            Currently employed as a <span
                            style={{fontWeight: "bold"}}>Software Development Engineer</span>,
                            I have graduated at the West University of Timisoara, obtaining a Master's Degree
                            in <span style={{fontWeight: "bold"}}>Big Data Technologies</span>. Passionate about the
                            world of <span style={{fontWeight: "bold"}}>development</span>, I embarked on a journey
                            of <span style={{fontWeight: "bold"}}>self-learning</span> through various proposed or
                            personal projects using a few well know <span
                            style={{fontWeight: "bold"}}>frameworks.</span>
                        </p>
                    </div>
                </div>
                <div className="portfolio" id="portfolio">
                    <div className="col-1-aboutme">
                        <h2>Portfolio & Projects</h2>
                        <p>Will be added soon!</p>
                    </div>
                </div>
                <div className="profexperience" id="experienceProf">
                    <div className="col-1-aboutme">
                        <h2>Professional Experience</h2>
                        <Timeline events={events}/>
                    </div>
                </div>
                <div className="educationalexperience" id="experienceEdu">
                    <div className="col-1-aboutme">
                        <h2>Educational Experience</h2>
                        <Timeline events={education}/>
                    </div>
                </div>
            </section>
        );
    }
}

export default Home;