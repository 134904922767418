import React from 'react';
import './timeline.css';

const Timeline = ({ events }) => {
    return (
        <div className="timeline-container">
            {events.map((event, index) => (
                <div key={index} className="timeline-event">
                    <div className="timeline-year">
                        {event.startMonth} {event.startYear} - {event.endMonth ? `${event.endMonth} ${event.endYear}` : 'Present'}
                    </div>
                    <div className="timeline-details">
                        <h3 className="timeline-title">{event.title}</h3>
                        <p className="timeline-description">{event.description}</p>
                        <div className="timeline-tags">
                            {event.tags.map((tag, tagIndex) => (
                                <span key={tagIndex} className={`timeline-tag ${tag.color}`}>{tag.name}</span>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Timeline;